import { intervalToDuration } from "date-fns";
import { MutableRefObject, useCallback, useEffect, useState } from "react";
import { RiPrinterLine } from "react-icons/ri";
import { showToast } from "../../../../components/CustomToast";
import { OutlinedCheckbox } from "../../../../components/OutlinedCheckbox";
import SendToPrinter from "../../../../components/SendToPrinter";
import SendToPrinterViaLan from "../../../../components/SendToPrinterViaLan";
import { useAuth } from "../../../../hooks/AuthContext";
import { usePrinters } from "../../../../hooks/PrintersContext";
import { i18n } from "../../../../localization/i18n";
import api from "../../../../services/api";
import { filamentConstants, roles } from "../../../../utils/constants";
import { IFileChoice, IFileStream } from "../../dtos";
import {
  DataBar,
  Field,
  FilamentConfigText,
  FlexCenter,
  Section,
} from "../../styles";
import {
  AlertMessage,
  Container,
  Footer,
  FormContainer,
  Header,
  InputLine,
  SaveText,
} from "./styles";
import SendToPrinterStream from "../../../../components/SendToPrinterStream";

interface IPrintControl {
  fileChoice: IFileChoice;
  setPrintHasStarted: (value: boolean) => void;
}

interface DataCostProps {
  filamentCost: string;
  coin: string | number;
}

const PrintControl = ({ fileChoice, setPrintHasStarted }: IPrintControl) => {
  const [selectedFileStream, setSelectedFileStream] = useState<IFileStream>();
  const [hasError, setHasError] = useState(false);

  const [contentLength, setContentLength] = useState<number>(0);
  const [received, setReceived] = useState<number>(0);
  const [progress, setProgress] = useState(0);

  const [printingId, setPrintingId] = useState<string>();
  const [cost, setCost] = useState<any>();
  const [costData, setCostData] = useState<DataCostProps>();
  const [printingFilamentCost, setPrintingFilamentCost] = useState<number>();

  const { defaultPrinter } = usePrinters();
  const { user } = useAuth();

  function resumePrinting() {
    setReceived(contentLength);
  }

  useEffect(() => {
    setCost(localStorage.getItem("filamentConfigs"));
  }, []);

  useEffect(() => {
    if (cost) {
      setCostData(JSON.parse(cost));

      if (costData !== null) {
        setPrintingFilamentCost(
          (Number(costData?.filamentCost) /
            filamentConstants.metersPerKilogram) *
          filamentConstants.densityGramsPerMeter
        );
      }
    }
  }, [cost, printingFilamentCost]);

  useEffect(() => {
    if (received && contentLength) {
      const newProgress: number = +((received / contentLength) * 100).toFixed(
        0
      );
      const diffNewProgress: number = +(newProgress / 2).toFixed(0);
      const diffProgress: number = +(progress / 2).toFixed(0);

      if (diffNewProgress > diffProgress) {
        setProgress(diffNewProgress * 2);
      }
    }
  }, [contentLength, received]);

  const startPrinting = async (fileStreamId: string) => {
    try {
      const { data }: any = await api.post(`/printings`, {
        stream_id: fileStreamId,
        device_id: localStorage.getItem('@FixitApp:Device')
      });
      setPrintingId(data.id);
    } catch (error: any) {
      error.response?.data?.message &&
        showToast({
          type: "error",
          message: i18n.t(`error.${error.response?.data.message}`),
        });
    }
  };

  const onEnd = useCallback(async () => {
    try {
      fileChoice.file_streams.length === 1 ?
        await api
          .put(`/file-choice/${fileChoice.id}/complete`)
          .then(() => window.location.reload()) : window.location.reload()
    } catch (error: any) {
      error.response?.data?.message &&
        showToast({
          type: "error",
          message: i18n.t(`error.${error.response?.data.message}`),
        });
    }
  }, [fileChoice.id]);

  // const printTime = intervalToDuration({ start: 0, end: (+(selectedFileStream?.standard_file.print_time ?? 0)) * 1000 });

  return (
    <Container>
      {!selectedFileStream || !printingId ? (
        <>
          <Header>
            <span>{`${i18n.t("orders.printChoose.printControl.header")}`}</span>
          </Header>

          <FormContainer>
            {fileChoice.file_streams?.map((stream) => {
              const activePrinting = stream.printings?.find((s) => s.is_active);
              return (
                <InputLine key={stream.id}>
                  {activePrinting && activePrinting.progress > 95 && (
                    <OutlinedCheckbox
                      activeBgColor="var(--dark-gray)"
                      streched={true}
                      label={i18n.t(
                        "orders.printChoose.printControl.completed",
                        {
                          print_option: `- ${stream.part}`,
                        }
                      )}
                      extralabel={stream.name}
                      checked={true}
                      readOnly={true}
                      handleChange={() => { }}
                    ></OutlinedCheckbox>
                  )}
                  {activePrinting && activePrinting.progress <= 95 && (
                    <OutlinedCheckbox
                      inactiveBgColor="var(--warning)"
                      streched={true}
                      label={i18n.t(
                        "orders.printChoose.printControl.tryagain",
                        {
                          print_option: ` - ${stream.part}`,
                        }
                      )}
                      extralabel={stream.name}
                      checked={selectedFileStream?.id === stream.id}
                      handleChange={() => {
                        setSelectedFileStream(stream);
                      }}
                    ></OutlinedCheckbox>
                  )}
                  {!activePrinting && (
                    <OutlinedCheckbox
                      streched={true}
                      label={i18n.t(
                        "orders.printChoose.printControl.selectStream",
                        {
                          print_option: ` - ${stream.part}`,
                        }
                      )}
                      extralabel={stream.name}
                      checked={selectedFileStream?.id === stream.id}
                      handleChange={() => {
                        setSelectedFileStream(stream);
                      }}
                    ></OutlinedCheckbox>
                  )}
                </InputLine>
              );
            })}
          </FormContainer>
          <Footer>
            {!selectedFileStream ||
              !fileChoice.file_streams?.length ||
              !selectedFileStream?.standard_file ||
              hasError ? (
              <AlertMessage>
                {hasError
                  ? `${i18n.t("orders.printChoose.printControl.error")}`
                  : !fileChoice.file_streams?.length || !selectedFileStream?.standard_file
                    ? `${i18n.t("orders.printChoose.printControl.empty")}`
                    : `${i18n.t("orders.printChoose.printControl.advice")}`}
              </AlertMessage>
            ) : user.role === roles.licensee || user.role === roles.employee ? (
              <SaveText onClick={() => startPrinting(selectedFileStream.id)}>
                {`${i18n.t("orders.printChoose.printControl.print")}`}
              </SaveText>
            ) : (
              <SaveText onClick={() => { }}>
                {!!selectedFileStream?.printings?.find((s) => s.is_active)
                  ? `${i18n.t("orders.printChoose.printControl.printing")}`
                  : `${i18n.t("orders.printChoose.printControl.notStarted")}`}
              </SaveText>
            )}
          </Footer>
        </>
      ) : (
        <>
          <FlexCenter>
            <RiPrinterLine size={64} />
          </FlexCenter>
          <FlexCenter>
            <h4>
              {`${i18n.t("orders.printStarting")}`} <br />{" "}
              {`${i18n.t("orders.printingSuccess")}`}
            </h4>
            <Section>
              <h2>{`${i18n.t("orders.printingData")}`}</h2>
              <DataBar>
                <Field>
                  <strong>{`${i18n.t("orders.solution")}:`}</strong>
                  {fileChoice.file_streams[0].name.split("_")[0]}
                </Field>
                <Field>
                  <strong>{`${i18n.t("orders.printingTime")}`}</strong>
                  {/* `${(printTime.hours ?? 0).toPrecision(2)}:${(printTime.minutes ?? 0).toPrecision(2)}:${(printTime.seconds ?? 0).toPrecision(2)}` */}
                  {selectedFileStream?.standard_file?.print_time ?? ""}
                </Field>
                {costData && printingFilamentCost ? (
                  <Field>
                    <strong>{`${i18n.t("orders.materialCost")}:`}</strong>
                    {printingFilamentCost.toFixed(2)}{" "}
                    {costData.coin.toString().split("/")[0]}
                  </Field>
                ) : (
                  <Field>
                    <strong>{`${i18n.t("orders.materialCost")}`}</strong>-
                  </Field>
                )}
                <Field>
                  <strong>{`${i18n.t("orders.materialWeight")}`}</strong>
                  {selectedFileStream.material_weight}
                </Field>
                <Field>
                  <strong>{`${i18n.t("solutions.side")}:`}</strong>
                  {fileChoice.side}
                </Field>
              </DataBar>
              <div className="filament_config">{`${i18n.t(
                "printer.filament.configure"
              )}`}</div>
            </Section>
            {
              defaultPrinter?.lan_connection ?
                /* <SendToPrinterViaLan
                  printingId={printingId}
                  progress={progress}
                  setContentLength={setContentLength}
                  setReceived={setReceived}
                  resumePrinting={resumePrinting}
                  printer={fileChoice.printer}
                  fileStream={selectedFileStream}
                  printStart={() => setProgress(1)}
                  printEnd={() => {
                    onEnd();
                  }}
                  setPrintHasStarted={(value) => setPrintHasStarted(value)}
                />

                */
                <SendToPrinterStream
                  printingId={printingId}
                  progress={progress}
                  setContentLength={setContentLength}
                  setReceived={setReceived}
                  resumePrinting={resumePrinting}
                  printer={fileChoice.printer}
                  fileStream={selectedFileStream}
                  printStart={() => setProgress(1)}
                  printEnd={() => {
                    onEnd();
                  }}
                  setPrintHasStarted={(value) => setPrintHasStarted(value)}
                />
                :
                <SendToPrinter
                  printingId={printingId}
                  progress={progress}
                  setContentLength={setContentLength}
                  setReceived={setReceived}
                  resumePrinting={resumePrinting}
                  printer={fileChoice.printer}
                  fileStream={selectedFileStream}
                  printStart={() => setProgress(1)}
                  printEnd={() => {
                    onEnd();
                  }}
                  setPrintHasStarted={(value) => setPrintHasStarted(value)}
                />
            }

          </FlexCenter>
        </>
      )}
    </Container>
  );
};

export default PrintControl;
